<template>
  <main class="view view--login">
    <img class="logo" src="img/logo-negative.png" alt="" width="182" />

    <LoginForm v-on:loginsuccess="checkAccounts"></LoginForm>

    <a class="forgot-pass" href="https://app.prixo.io/password/reset">{{
      $t("Forgot your password?")
    }}</a>
  </main>
</template>

<script>
import LoginForm from "./../components/LoginForm.vue";

export default {
  components: {
    LoginForm,
  },
  methods: {
    checkAccounts: function (data) {
      if (data) {
          this.$config.set("jwt", data);
      }

      // When more than 1 account, goto account selector
      this.$api.getAccounts().then((data) => {
        this.$config.set("accounts", data);
        if (data.length > 1) {
          // Goto accounts selector
          this.$router.push({ name: "accounts", params: { accounts: data } });
        } else if (data.length == 1 && !isNaN(data[0].id)) {
          // Set only account and goto home
          this.$config.set("account", data[0]);
          this.$router.push({ name: "home" });
        }
      });
    },
  },
  mounted: function () {
    if (this.$config.get("account") && this.$config.get("jwt")) {
      this.$router.replace({ name: "home" });
    }
    this.$helper.promptHomescreen();
  },
};
</script>
