<template>
  <form
    class="form-login"
    @submit.prevent="onSubmit"
    @keydown="form.errors.clear($event.target.name)"
  >
    <div class="help is-danger">
      <div class="mb-2" v-text="form.errors.get('error')"></div>
    </div>
    <input
      name="email"
      class="form-control form-control--user"
      type="text"
      :placeholder="$t('Username')"
      v-model="form.email"
    />
    <span
      class="help is-danger"
      v-if="form.errors.has('email')"
      v-text="form.errors.get('email')"
    ></span>

    <input
      name="password"
      class="form-control form-control--pass"
      type="password"
      :placeholder="$t('Password')"
      v-model="form.password"
    />
    <span
      class="help is-danger"
      v-if="form.errors.has('password')"
      v-text="form.errors.get('password')"
    ></span>

    <input
      type="submit"
      class="btn btn-outline-secondary"
      :value="$t('Login')"
      :disabled="form.errors.any()"
    />
  </form>
</template>

<script>
//import api from "../config/api.js"

class Errors {
  constructor() {
    this.errors = {};
  }
  get(field) {
    if (this.errors[field]) {
      return this.errors[field][0];
    }
  }

  has(field) {
    return this.errors.hasOwnProperty(field);
  }

  any() {
    return Object.keys(this.errors).length > 0;
  }

  record(errors) {
    this.errors = errors;
  }

  clear(field) {
    if (field) {
      delete this.errors[field];
      delete this.errors["error"];
      return;
    }
    this.errors = {};
  }
}

class Form {
  constructor(data) {
    this.originalData = data;

    for (let field in data) {
      this[field] = data[field];
    }

    this.errors = new Errors();
  }
  reset() {
    for (let field in this.originalData) {
      this[field] = "";
    }
  }
  data() {
    let data = {};

    for (let property in this.originalData) {
      data[property] = this[property];
    }

    return data;
  }
  submit(requestType, url) {
    return fetch(url + "/auth/login", {
      method: requestType,
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      credentials: "include",
      body: JSON.stringify(this.data()),
    });
  }
}

export default {
  name: "LoginForm",
  mounted: function () {
    if (this.$route.query.error) {
      this.form.errors.record({ error: [this.$route.query.error] });
    }
  },
  methods: {
    onSubmit: function () {
      this.form
        .submit("POST", this.$api.API_URL)
        .then((data) => {
          if (data.status == 200 || data.status == 422) {
            return data.text();
          } else {
            return Promise.reject({
              error: [
                this.$t(
                  "Unable to login! Please check your password or username"
                ),
              ],
            });
          }
        })
        .then((data) => {
          if (data.errors) {
            this.form.errors.record(data.errors);
          } else {
            this.form.errors.clear();
            this.form.reset();
            this.$emit("loginsuccess", data);
          }
        })
        .catch((error) => {
          this.form.errors.record(error);
        });
    },
  },
  data: function () {
    return {
      form: new Form({
        email: "",
        password: "",
      }),
    };
  },
};
</script>
